.blog {
  padding-bottom: 100px;
  margin-top: 67px;
  max-width: 1200px;
}

.blog__article__title {
  font-size: 32px;
  font-weight: 500;
  padding-right: 20px;
}

.blog__article__date {
  font-size: 18px;
  font-weight: 500;
  padding-right: 20px;
  padding-bottom: 20px;
  color: gray;
}

.blog__article__content {
  padding-top: 30px;
  font-size: 18px;
  font-weight: 300;
  max-width: 800px;
}

.blog__article__content h3 {
  font-size: 24px;
  font-weight: 500;
  padding-bottom: 10px;
}

.blog__article__content h4 {
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 10px;
}